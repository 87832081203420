const Login = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/general/Login.vue');
const ForgotPassword = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/general/ForgotPassword.vue');
const ChangePassword = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/general/ChangePassword.vue');
const SiteIssue = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/general/SiteIssue.vue');
const SignUp = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/general/SignUp.vue');
const VueHome = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/general/VueHome.vue');

export default [
  {
    name: Login,
    path: '/',
    component: Login,
  },
  {
    name: Login,
    path: '/login',
    component: Login,
  },
  {
    name: SignUp,
    path: '/signUp',
    component: SignUp,
  },
  {
    name: SiteIssue,
    path: '/siteIssue',
    component: SiteIssue,
  },
  {
    name: ChangePassword,
    path: '/changePassword',
    component: ChangePassword,
  },
  {
    name: ForgotPassword,
    path: '/forgotPassword',
    component: ForgotPassword,
  },
  {
    name: VueHome,
    path: '/vueHome',
    component: VueHome,
  },
];
