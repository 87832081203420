import User from '@/domain/user';

export default {
  state: {
    loggedIn: false,
    user: new User(),
  },
  mutations: {
    LOGIN(state, object) {
      state.loggedIn = true;
      state.user = new User(object);
    },
    LOGOUT(state) {
      state.loggedIn = false;
      state.user = null;
    },
  },
  actions: {
    login(context, object) {
      context.commit('LOGIN', object);
    },
    logout(context) {
      context.commit('LOGOUT');
    },
  },
  getters: {
    loggedIn: (state) => state.loggedIn,
    user: (state) => state.user,
  },
};
