import apiProcessor from '@/services/ApiProcessor';

function login(username, password) {
  return apiProcessor.post('api/authenticate', { username, password });
}

function verify(username, verificationCode) {
  return apiProcessor.post('api/authenticate/verify', { username, verificationCode });
}

function forgotPassword(email) {
  return apiProcessor.post('api/authenticate/forgotPassword', { email });
}

function resetPassword(email, verificationCode, password) {
  return apiProcessor.post('api/authenticate/resetPassword', { email, verificationCode, password });
}

function logout() {
  return apiProcessor.post('api/authenticate/logout')
    .then(() => {
      this.$store.dispatch('logout');
      localStorage.setItem('token', null);
      localStorage.setItem('user', null);
      apiProcessor.removeHeader('Authorization');
    }).catch((error) => {
      console.log('Error logout');
      console.log(error);
    });
}

function signUp(user) {
  return apiProcessor.post('api/authenticate/newUser', user)
    .then(() => {})
    .catch((error) => {
      console.log('Error login');
      throw error;
    });
}

function resendAuthentication(user) {
  return apiProcessor.post('api/authenticate/resendAuthentication', user)
    .then(() => {})
    .catch((error) => {
      console.log('Error login');
      throw error;
    });
}

export default {
  login,
  signUp,
  logout,
  verify,
  forgotPassword,
  resetPassword,
  resendAuthentication,
};
