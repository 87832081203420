import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import user from './modules/user';

Vue.use(Vuex);

const modules = [
  user,
];
const plugins = [createPersistedState({
  storage: window.sessionStorage,
})];

export default new Vuex.Store({
  modules,
  plugins,
  strict: false,
});
