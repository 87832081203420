import Vue from 'vue';

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

import * as VueGoogleMaps from 'vue2-google-maps';

import './plugins';
import '@/assets/css';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import App from './App.vue';
import router from './router';
import store from './store';

library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;
Vue.use(Buefy);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDM-Pcqtd7KX2YFlzynCwYIUdivXSCBkAk',
    libraries: 'places',
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
