import gameRoutes from './game';
import generalRoutes from './general';
import sportRoutes from './sport';
import teamRoutes from './team';
import userRoutes from './user';

export default [
  ...gameRoutes,
  ...generalRoutes,
  ...sportRoutes,
  ...teamRoutes,
  ...userRoutes,
];
