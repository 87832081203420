const SportView = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/sport/SportView.vue');
const NamedEventView = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/sport/NamedEventView.vue');
const SeriesView = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/sport/SeriesView.vue');

export default [
  {
    name: SportView,
    path: '/sport/:sportId',
    component: SportView,
  },
  {
    name: NamedEventView,
    path: '/namedEvent/:namedEventId',
    component: NamedEventView,
  },
  {
    name: SeriesView,
    path: '/series',
    component: SeriesView,
  },

];
