import {
  get, has, isArray, isObject, omit,
} from 'lodash';
import axios from 'axios';
import qs from 'qs';

// create axios instance and attach response interceptor
const instance = axios.create({
  baseURL: '',
  paramsSerializer(params) {
    return qs.stringify(params, { indices: false, skipNulls: true });
  },
});

function requestHandler(request) {
  let domain = request.url;
  let params = new URLSearchParams();
  if (domain.includes('?')) {
    const [theDomain, query] = domain.split('?');
    domain = theDomain;
    params = new URLSearchParams(query);
  }

  params.append('t', new Date().getTime().toString());
  const url = `${domain}?${params.toString()}`;

  return {
    ...request,
    // Browsers do these neat thing where they cache API calls if you use the 'back' button.
    // By adding the '?t=timestamp' to each API call we are essentially
    // telling the browser this is a unique request everytime.
    url,
  };
}
// Normalize response objects and throws "success=false" case
// to be caught by .catch block to match expected behavior.
// {
//    "success": true,
//    "records": []
//       or
//    "record": {}
// }
function responseNormalizer(response) {
  const { data } = response;

  // Handle when api returns error in response body:
  // {
  //    "message": "Some error message.",
  //    "success": false
  // }
  // Or
  // [{
  //    "message": "Some error message.",
  //    "success": false
  // }]
  const isSuccess = get(data, 'success', true);
  const isSuccessArray = get(data, '0.success', true);

  if (isSuccess === false || isSuccessArray === false) {
    throw get(data, 'message', get(data, 'errorMsg', get(data[0], 'message', 'An error occurred.')));
  }

  // Handle extra result wrapper:
  // {
  //    "result": {
  //      "message": "Some error message.",
  //      "status": "Error",
  //    }
  // }
  const hasStatus = get(data, 'result.status', 'Success');

  if (hasStatus === 'Error') {
    throw get(data, 'result.message', 'An error occurred.');
  }

  // Handle extra array wrapper:
  // [
  //   {
  //     "success": true,
  //     "records": [
  //       <record>,
  //       <record>
  //     ]
  //   }
  // ]
  if (isArray(data) && get(data, '0.success') === true) {
    return {
      ...response,
      data: data[0],
    };
  }

  // Handle array without object wrapper:
  // [
  //   <record>,
  //   <record>,
  //   ...
  // ]
  if (isArray(data)) {
    return {
      ...response,
      data: {
        success: true,
        records: data,
      },
    };
  }

  // Handle single object without wrapper:
  // {
  //   "prop": "value",
  //   "prop2": "value2",
  //   ...
  // }
  if (isObject(data) && !has(data, 'success')) {
    return {
      ...response,
      data: {
        success: true,
        record: data,
      },
    };
  }

  // If it somehow got to here,
  // the api is returning in the expected format.
  return response;
}

function errorResponseHandler(error) {
  // const { url } = error.config;
  const isStatus401 = error?.response?.status === 401;
  const isPermissionDeniedPage = window?.location?.href.includes('error/permission-denied');

  if (isStatus401 && !isPermissionDeniedPage) {
    // window.location.href = `#/error/permission-denied?error=API_SERVICE_CALL&redirect=${encodeURIComponent(window.location.href)}&error_description=unauthorized_url: ${encodeURIComponent(url)}`;

    window.location.href = '#/login';
    return Promise.resolve();
  }

  return Promise.reject(error);
}

function getAndReturnDataByKey(requestParams, key, defaultValue) {
  return instance.get(...requestParams)
    .then((response) => get(response, key, defaultValue));
}

function getRecords(...params) {
  return getAndReturnDataByKey(params, 'data.records', []);
}

function getRecord(...params) {
  return getAndReturnDataByKey(params, 'data.record', {});
}

function getValue(...params) {
  return getAndReturnDataByKey(params, 'data.value', {});
}

function addHeader(key, value) {
  instance.defaults.headers.common[key] = value;
}

function removeHeader(key) {
  instance.defaults.headers.common = omit(instance.defaults.headers.common, [key]);
}

instance.interceptors.request.use(requestHandler);
instance.interceptors.response.use(responseNormalizer, errorResponseHandler);
instance.getRecords = getRecords;
instance.getRecord = getRecord;
instance.getValue = getValue;
instance.addHeader = addHeader;
instance.removeHeader = removeHeader;
export default instance;
