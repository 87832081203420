import { get } from 'lodash';

export default class User {
  constructor(user = {}) {
    Object.keys(user).forEach((key) => { this[key] = user[key]; });
  }

  hasPermission = function (permission) {
    const userPermissions = get(this, 'permissions');
    return userPermissions.includes(permission);
  }
}
