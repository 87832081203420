import Vue from 'vue';
import Router from 'vue-router';

import routes from './routes';

Vue.use(Router);

const router = new Router({
  routes,
  scrollBehavior(to, from) {
    if (to.query._ || to.path !== from.path) {
      return { x: 0, y: 0 };
    }
    return {};
  },
});

export default router;
