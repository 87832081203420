<template lang="pug">
.header
  nav.navbar.is-light(role='navigation' aria-label='main navigation')
    .navbar-brand
      .navbar-item
        a(@click="myProfile")
          img(src="@/assets/fans.jpeg")
      .navbar-item.site-label.space-l-lg
        strong FANTRACKER (Beta)
    .navbar-end.space-r-lg.padding-r-lg
      .navbar-item.has-dropdown.is-hoverable.is-mobile
        a.navbar-link(v-if="user")
          | {{ user.firstName }} {{ user.lastName }}
        .navbar-dropdown
          a(@click="myProfile").navbar-item
            span.space-r-sm
              font-awesome-icon.space-l-sm(icon='user')
            span   My Profile
          a(@click="goToInbox").navbar-item
            span.space-r-sm
              font-awesome-icon.space-l-sm(icon='envelope')
            span  Inbox
          a(@click="changePassword").navbar-item
            span.space-r-sm
              font-awesome-icon.space-l-sm(icon='key')
            span  Change Password
          a(@click="logout").navbar-item
            span.space-r-sm
              font-awesome-icon.space-l-sm(icon='right-from-bracket')
            span  Logout
          hr.navbar-divider
          a(@click="reportIssue").navbar-item
            | Report an issue
</template>

<script>
import LoginService from '@/services/LoginService';
import apiProcessor from '@/services/ApiProcessor';

import UserMenu from '@/components/general/UserMenu.vue';

export default {
  name: 'HeaderView',
  components: {
    UserMenu,
  },
  asyncComputed: {
    user() {
      return this.$store.getters.user;
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
  methods: {
    myProfile() {
      this.$router.push('/user');
    },
    changePassword() {
      this.$router.push('/changePassword');
    },
    goToInbox() {
      this.$router.push('/inbox');
    },
    reportIssue() {
      this.$router.push('/siteIssue');
    },
    logout() {
      LoginService.logout()
        .then(() => {
          localStorage.setItem('token', null);
          localStorage.setItem('user', null);
          apiProcessor.removeHeader('Authorization');
          this.$store.dispatch('logout');
          this.$router.push('/login');
        });
    },
  },
};

</script>

<style scoped>
  .header {
    vertical-align: center;
    align-content: center;
  }
  .site-title {
    contain: content;
    text-align: center;
  }
  img {
    max-height: 60px;
  }
  .site-label {
    font-weight: bold;
    font-size: x-large;
  }
  h4 {
    text-align: center;
  }
  .user-details {
    align-content: end;
    text-align: right;
    vertical-align: center;
  }
  user-menu {
    height: 60px;
  }
  .search-logo {

  }

</style>
