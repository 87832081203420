import Vue from 'vue';

import AsyncComputed from 'vue-async-computed';
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';
import Notifications from 'vue-notification';
import VModal from 'vue-js-modal';

Vue.use(AsyncComputed);
Vue.use(VueGoodTablePlugin);
Vue.use(Notifications);
Vue.use(VModal);
