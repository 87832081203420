<template lang="pug">
  .main-app
    .top-bar(v-if="isLoggedIn")
      Header
    notifications
    .app-content
      router-view
</template>

<script>
import Header from './components/general/Header.vue';

export default {
  name: 'App',
  components: {
    Header,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters.loggedIn;
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
</style>
