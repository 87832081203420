const UserView = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/user/UserView.vue');
const InboxView = () => import(/* webpackChunkName: "helpRoutes" */ '@/views/user/InboxView.vue');

export default [
  {
    name: UserView,
    path: '/user',
    component: UserView,
  },
  {
    name: UserView,
    path: '/user/:userId',
    component: UserView,
  },
  {
    name: InboxView,
    path: '/inbox',
    component: InboxView,
  },
];
